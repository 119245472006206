body {
    margin: 0;
    padding-top: 55px;
    overflow-y: scroll;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, "Microsoft YaHei", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-content {
    padding: 4px;
}

.small-icon .icon {
    font-size: 1em !important;
}

.custom-footer {
    font-size: 1.1em;
}

@media only screen and (max-width: 600px) {
    .hide-on-mobile {
        display: none !important;
    }
}
